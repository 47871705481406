import React, { useEffect, useRef, useState } from 'react';
import { Asset } from 'contentful';
import Image from 'next/image';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import axios from 'axios';
import logger from '@greenlightme/logger';

interface AppFeatureAnimationItemProps {
  media: Asset;
  play?: boolean;
  onEnded?: () => void;
}

export function AppFeatureAnimationItem({
  media,
  play,
  onEnded
}: AppFeatureAnimationItemProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const lottieRef = useRef<LottieRefCurrentProps>(null);
  const [lottieData, setLottieData] = useState(null);
  const [played, setPlayed] = useState(false);
  const contentType = media.fields.file.contentType;

  const handleEnded = () => {
    onEnded?.();
    setPlayed(true);
  };

  useEffect(() => {
    if (contentType.includes('json') && play && !lottieData) {
      axios
        .get(media.fields.file.url)
        .then(({ data }) => setLottieData(data))
        .catch((err) => {
          logger.error('Error fetching lottie data', err);
          console.error('Error fetching lottie data', err);
        });
    }
  }, [contentType, play, lottieData]);

  useEffect(() => {
    if (videoRef.current) {
      if (play) {
        if (played) {
          videoRef.current.currentTime = 0;
        }
        const playPromise = videoRef.current.play();
        if (playPromise !== undefined) {
          playPromise.catch((err) => {
            logger.error('Error playing video', err);
            console.error('Error playing video', err);
          });
        }
      } else {
        videoRef.current.pause();
      }
    }

    if (lottieRef.current) {
      if (play) {
        if (played) {
          lottieRef.current.goToAndStop(0);
        }
        lottieRef.current.play();
      } else {
        lottieRef.current.stop();
      }
    }
  }, [videoRef.current, lottieRef.current, play, played]);

  if (contentType.includes('image')) {
    return (
      <Image
        src={media.fields.file.url}
        alt={media.fields.title}
        width={media.fields.file.details.image?.width}
        height={media.fields.file.details.image?.height}
        className="h-full w-full object-fill"
        loader={({ src }) => src}
        unoptimized={true}
        data-testid="app-feature-animation-image"
      />
    );
  }

  if (contentType.includes('video')) {
    return (
      <video
        key={media.fields.file.url}
        ref={videoRef}
        muted
        playsInline
        disablePictureInPicture
        controls={false}
        className="max-h-full"
        data-testid="app-feature-animation-video"
        onEnded={handleEnded}
        onLoadedData={() => {
          if (play && videoRef.current) {
            videoRef.current.play().catch((err) => {
              logger.error('Error playing video on load', err);
              console.error('Error playing video on load', err);
            });
          }
        }}
      >
        <source src={media.fields.file.url} type={contentType} />
        <track kind="captions" srcLang="en" />
      </video>
    );
  }

  if (contentType.includes('json')) {
    return (
      <Lottie
        lottieRef={lottieRef}
        animationData={lottieData}
        autoPlay={false}
        loop={false}
        onComplete={handleEnded}
        className="h-full w-full object-fill"
        data-testid="app-feature-animation-lottie"
      />
    );
  }

  return null;
}
